import { render, staticRenderFns } from "./NineCalculator.vue?vue&type=template&id=efdd574c&scoped=true&"
import script from "./NineCalculator.vue?vue&type=script&lang=js&"
export * from "./NineCalculator.vue?vue&type=script&lang=js&"
import style0 from "./NineCalculator.vue?vue&type=style&index=0&id=efdd574c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_@vue+compiler-sfc@3.2.33_cache-loader@4.1.0_css-loader@3.6.0_vue-template-c_lvig2la6zzdinm6tu5vgv7idzq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efdd574c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.6.13_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VForm,VIcon,VImg,VTextField})
