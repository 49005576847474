<template>
  <div class="mb-5">
    <header class="svg-logo d-flex align-center justify-center">
      <v-img class="mr-5" max-width="50px" src="../../../public/assets/img/svg/nine-calculator.svg"></v-img>
      <h1 class="text-h5">Nine Calculator</h1>
    </header>
    <p>Privacy policy: The Android app doesn't collect any data.</p>
    <div>
      Credit:
      <Url :dark-mode="$vuetify.theme.isDark" href="https://www.youtube.com/watch?v=zokbvriYovw&t=10m44s"
        >Scott Flansburg
      </Url>
    </div>
    <Url :dark-mode="$vuetify.theme.isDark" href="https://math.stackexchange.com/a/4020127/521439"
      >A mathematical explanation</Url
    >.

    <div>You can press the up/down arrow keys to increment/decrement the number.</div>

    <v-form ref="form" class="mt-4" autocomplete="off">
      <input autocomplete="off" type="hidden" />

      <v-checkbox v-model="isTutorial" label="Explain calculation process"></v-checkbox>

      <v-text-field
        ref="input"
        v-model.trim="inputNumberString"
        :rules="rules"
        autofocus
        class="mt-5"
        label="Type a number with at least 2 digits"
        type="text"
        @keypress="preventNonNumber"
        @keydown.up="incrementNumber"
        @keydown.down="decrementNumber"
      ></v-text-field>
    </v-form>

    <transition name="slide">
      <div v-if="isConvertTo9 && calculationSteps.length > 0" class="output">
        How does this {{ commaSeparated(number.toString().length) }}-digit number turn into 9?<br />
        {{ commaSeparated(number) }}
        <div v-for="step of calculationSteps" :key="step.iteration.toString()">
          <div v-show="isTutorial">
            <div v-if="step.tutorial">
              {{ getInstruction(step) }}
            </div>
            <v-icon>{{ mdiArrowDown }}</v-icon>
          </div>
          <span class="iteration">#{{ commaSeparated(step.iteration) }})</span>
          {{ step.calculation }}
        </div>

        <v-icon color="green">{{ mdiCheckBold }}</v-icon>
        Success!
      </div>
    </transition>
  </div>
</template>

<script>
import { convertTo9 } from "@/views/products/calculators";
import { mdiArrowDown, mdiCheckBold } from "@mdi/js";
import Url from "@/components/Url";
import { mapGetters } from "vuex";

export default {
  name: "NineCalculator",
  components: { Url },
  data() {
    return {
      inputNumberString: "",
      number: null,
      isTutorial: true,
      rules: [
        number => !isNaN(number.toString()) || "Must be a number",
        number => number.toString().length > 1 || "Must have at least 2 digits"
      ],
      calculationSteps: [],
      mdiCheckBold,
      mdiArrowDown
    };
  },
  watch: {
    inputNumberString(inputNumberString) {
      this.prepareToConvertTo9(inputNumberString);
    },
    isTutorial() {
      this.prepareToConvertTo9(this.inputNumberString);
    }
  },
  computed: {
    ...mapGetters(["commaSeparated"]),
    isConvertTo9() {
      return this.number && BigInt(this.number) >= 9n;
    }
  },
  methods: {
    async prepareToConvertTo9(inputNumberString) {
      this.number = BigInt(inputNumberString);
      if (!this.$refs.form.validate()) {
        return;
      }
      this.calculationSteps = [];
      this.calculationSteps = await convertTo9(this.number);
    },
    getInstruction(step) {
      const { iteration } = step;
      if (iteration <= 3n) {
        return step.tutorial;
      }
      if (iteration === 4) {
        return "Again";
      }
      return "";
    },
    preventNonNumber(e) {
      if (isNaN(e.key)) {
        e.preventDefault();
      }
    },
    incrementNumber() {
      const incrementedNumber = BigInt(this.inputNumberString || 9) + 1n;
      this.inputNumberString = incrementedNumber.toString();
    },
    decrementNumber() {
      const decrementedNumber = BigInt(this.inputNumberString || 11) - 1n;
      if (!decrementedNumber > 9n) {
        return;
      }
      this.inputNumberString = decrementedNumber.toString();
    }
  }
};
</script>

<style scoped>
.output {
  white-space: nowrap;
  overflow-y: hidden;
}

/*noinspection CssUnusedSymbol*/
.slide-leave,
.slide-enter-to {
  max-height: 100vh;
}

.iteration {
  color: blue;
}

@media (prefers-color-scheme: dark) {
  .iteration {
    color: cyan;
  }
}
</style>
